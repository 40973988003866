import {
	headingsPlugin,
	linkPlugin,
	linkDialogPlugin,
	listsPlugin,
	thematicBreakPlugin,
	UndoRedo,
	BoldItalicUnderlineToggles,
	toolbarPlugin,
	codeBlockPlugin,
	SandpackConfig,
	sandpackPlugin,
	imagePlugin,
	codeMirrorPlugin,
	markdownShortcutPlugin, quotePlugin, tablePlugin
} from '@mdxeditor/editor';
import React from 'react';

const defaultSnippetContent = `
export default function App() {
  return (
    <div className="App">
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
    </div>
  );
}
`.trim()

const simpleSandpackConfig: SandpackConfig = {
	defaultPreset: 'react',
	presets: [
		{
			label: 'React',
			name: 'react',
			meta: 'live react',
			sandpackTemplate: 'react',
			sandpackTheme: 'light',
			snippetFileName: '/App.js',
			snippetLanguage: 'jsx',
			initialSnippetContent: defaultSnippetContent
		},
	]
}

export const mdxEditorPlugins = [
	headingsPlugin(),
	// the default code block language to insert when the user clicks the "insert code block" button
	codeBlockPlugin({defaultCodeBlockLanguage: 'js'}),
	sandpackPlugin({ sandpackConfig: simpleSandpackConfig }),
	codeMirrorPlugin({ codeBlockLanguages: { js: 'JavaScript', shell: 'Shell', css: 'CSS', "": "text" } }),
	linkPlugin(),
	linkDialogPlugin({linkAutocompleteSuggestions: ['https://virtuoso.dev', 'https://mdxeditor.dev']}),
	listsPlugin(),
	thematicBreakPlugin(),
	markdownShortcutPlugin(),
	imagePlugin(),
	quotePlugin(),
	tablePlugin(),
	toolbarPlugin({
		toolbarContents: () => (
			<>
				{' '}
				<UndoRedo />
				<BoldItalicUnderlineToggles />
			</>
		)
	})
];

// Add other exports or configurations as needed
