import React, { useEffect, useState } from 'react';
import '@mdxeditor/editor/style.css';
import { mdxEditorPlugins } from './mdx-editor-plugins';
import {MDXEditor} from '@mdxeditor/editor';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	useParams
} from 'react-router-dom';

function EditorPage() {
	const { id } = useParams();
	const [markdown, setMarkdown] = useState('waiting for content...');

	useEffect(() => {
		// 以 id 为基础异步加载 Markdown 内容
		const fetchMarkdown = async () => {
			const encodedFragment = encodeURIComponent(`$id#${id}`);
			const fetchUrl = `https://gpt-docs-data-worker.kuanhsiaokuo.dev/fragments?filters=${encodedFragment}`;
			const response = await fetch(fetchUrl);
			const data = await response.json();
			if (data && data.length > 0) {
				const { title, extra_notes, md_content } = data[0];
				const content = `${title}\n---\n${extra_notes}\n---\n${md_content}`;
				console.log(content);
				setMarkdown(content);
			}
		};

		if (id) {
			fetchMarkdown();
		}
	}, [id]);

	return (
		<div>
			<MDXEditor key={markdown} markdown={markdown} plugins={mdxEditorPlugins}
			/>
		</div>
	);
}

function Example() {
	// const content = `Hello MdxEditor!`
	const content = `
Hello

---

[MdxEditor](https://mdxeditor.dev/)!
`;
	return (
		<MDXEditor markdown={content} plugins={mdxEditorPlugins}/>
	);
}


function App() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Example />} />
				<Route path="/:id" element={<EditorPage />} />
			</Routes>
		</Router>
	);
}

export default App;
